<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title"
                    ><span class="el-tree-node__label">游客影响评估</span></span
                >
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="年份" prop="NF" class="from-item-block">
                    <el-date-picker
                        v-model="formdata.dataDic.NF"
                        type="year"
                        placeholder="选择年份"
                        value-format="yyyy"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="月份" prop="YF" class="from-item-block">
                    <el-select
                        v-model="formdata.dataDic.YF"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in yfenumData"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="PG" label="评估" class="from-item-block">
                    <el-select
                        v-model="formdata.dataDic.PG"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in PGList"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item
                    prop="PGSM"
                    label="评估说明"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.PGSM"
                        type="textarea"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "ykyxpg",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "110101",
                dataDic: {
                    glycbtid: this.heritageId,
                    NF: "",
                    YF: "",
                    PG: "",
                    PGSM: "",
                },
            },
            rules: {
                NF: [
                    {
                        required: true,
                        message: "请选择年份",
                        trigger: "change",
                    },
                ],
                YF: [
                    {
                        required: true,
                        message: "请选择月份",
                        trigger: "change",
                    },
                ],
                PG: [
                    {
                        required: true,
                        message: "请选择评估",
                        trigger: "change",
                    },
                ],
                PGSM: [
                    {
                        required: true,
                        message: "请填写评估说明",
                        trigger: "change",
                    },
                ],
            },
            enumData: [],
            yfenumData: [], // 月份枚举值
            PGList: [], // 评估枚举值
        };
    },
    mounted() {
        this.GetEnum();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "110101",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "PG") {
                    this.PGList = v.dataItems;
                } else if (v.COLUMNID === "YF") {
                    this.yfenumData = v.dataItems;
                }
            });
        },
    },
};
</script>

<style></style>
